var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "light-bg"
  }, [_c('ul', [_c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Enrollment Date:")]), _c('span', [_vm._v(_vm._s(_vm.policy.enrollment_date))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Effective Date:")]), _c('span', [_vm._v(_vm._s(_vm.policy.effective_date) + " ")])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Billing Date:")]), _c('span', [_vm._v(_vm._s(_vm.policy.bill_date) + " ")])]), _vm.policy.status == 'TERMED' || _vm.policy.status == 'WITHDRAWN' ? _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Term Date:")]), _c('span', [_vm._v(" " + _vm._s(_vm.policy.term_date) + " ")])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "memberInformation"
  }, [_c('h2', [_vm._v("Primary Information")]), _c('div', {
    staticClass: "memberProfile_Image"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v(_vm._s(_vm.fullname))]), _c('span', {
    staticClass: "memberCntc"
  }, [_c('i', {
    staticClass: "fas fa-envelope"
  }), _vm._v(" " + _vm._s(_vm.personalInfo.email) + " "), _c('valid-icon', {
    attrs: {
      "is-valid": _vm.personalInfo.is_email_valid,
      "className": "valid-icon"
    }
  })], 1), _c('span', {
    staticClass: "memberCntc"
  }, [_c('i', {
    staticClass: "fas fa-phone-alt"
  }), _vm._v(" " + _vm._s(_vm.formattedPhone(_vm.personalInfo.phone_1)) + " "), _c('valid-icon', {
    attrs: {
      "is-valid": _vm.personalInfo.is_phone1_valid,
      "className": "valid-icon"
    }
  })], 1)]), _c('div', {
    staticClass: "light-bg"
  }, [_c('div', {
    staticClass: "memberDetail_lists"
  }, [_c('ul', [_c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Gender:")]), _c('span', [_vm._v(_vm._s(_vm.personalInfo.gender))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("DOB:")]), _c('span', [_vm._v(_vm._s(_vm.personalInfo.birth_date))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("SSN:")]), _c('span', [_vm._v(_vm._s(_vm.personalInfo.ssn))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Phone 1:")]), _c('span', [_vm._v(" " + _vm._s(_vm.formattedPhone(_vm.personalInfo.phone_1)) + " "), _vm.formattedPhone(_vm.personalInfo.phone_1) != 'N/A' ? _c('valid-icon', {
    attrs: {
      "is-valid": _vm.personalInfo.is_phone1_valid
    }
  }) : _vm._e()], 1)]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Alt. Phone:")]), _c('span', [_vm._v(" " + _vm._s(_vm.formattedPhone(_vm.personalInfo.phone_2)) + " "), _vm.formattedPhone(_vm.personalInfo.phone_2) != 'N/A' ? _c('valid-icon', {
    attrs: {
      "is-valid": _vm.personalInfo.is_phone2_valid
    }
  }) : _vm._e()], 1)])])])]), _c('h4', [_vm._v("Address Information")]), _c('div', {
    staticClass: "light-bg"
  }, [_vm.personalInfo.address ? _c('div', {
    staticClass: "memberDetail_lists"
  }, [_c('ul', [_c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Address 1:")]), _c('span', [_vm._v(" " + _vm._s(_vm.personalInfo.address.address1 ? _vm.personalInfo.address.address1 : 'N/A') + " ")])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Address 2:")]), _c('span', [_vm._v(" " + _vm._s(_vm.personalInfo.address.address2 ? _vm.personalInfo.address.address2 : 'N/A') + " ")])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("City:")]), _c('span', [_vm._v(_vm._s(_vm.personalInfo.address.city))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("State:")]), _c('span', [_vm._v(_vm._s(_vm.personalInfo.address.state))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("ZIP Code:")]), _c('span', [_vm._v(_vm._s(_vm.personalInfo.address.zip))])])])]) : _vm._e()]), _c('div', [_vm.beneficiaryInfo.length > 0 ? _c('div', [_c('h4', [_vm._v("Beneficiary Information")]), _c('div', {
    staticClass: "beneficiary-card"
  }, [_c('div', {
    staticClass: "light-bg table_content"
  }, [_c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.beneficiaryInfo, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.first_name) + " " + _vm._s(item.last_name))]), _c('td', [_vm._v(_vm._s(item.relationship))]), _c('td', [_vm._v(" " + _vm._s(item.is_contigent ? 'Contingent' : 'Beneficiary') + " ")])]);
  }), 0)])])])]) : _vm._e()])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Name")]), _c('td', [_vm._v("Relation")]), _c('td', [_vm._v("Type")])])]);
}]

export { render, staticRenderFns }
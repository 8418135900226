var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.documents.length > 0 ? _c('div', [_c('h4', [_vm._v("Documents")]), _c('div', {
    staticClass: "light-bg no-padding",
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.documents, function (d, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(d.filename))]), _c('td', [_c('download-button', {
      attrs: {
        "link": d.url
      }
    })], 1)]);
  }), 0)])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th'), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Action")])])]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm.type == 'billing' ? _c('span', [_vm._v(" Billing Method ")]) : _c('span', [_vm._v(" Recurring Billing Method ")])]), _c('div', {
    staticClass: "light-bg"
  }, [_c('span', {
    staticClass: "billing-categ"
  }, [_vm._v(" " + _vm._s(_vm.billing.payment_type) + " ")]), _vm.billing.details ? _c('ul', [_c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Bank Name:")]), _c('span', [_vm._v(" " + _vm._s(_vm.billing.details.name))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Account Name:")]), _c('span', [_vm._v(" " + _vm._s(_vm.billing.details.account_name))])]), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Account Number:")]), _c('span', [_vm._v(" " + _vm._s(_vm.billing.details.number))])]), _vm.billing.details.account_type ? _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Account Type:")]), _c('span', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.billing.details.account_type)))])]) : _vm._e(), _vm.billing.details.account_holder_type ? _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Account Holder Type:")]), _c('span', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.billing.details.account_holder_type)))])]) : _vm._e()]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
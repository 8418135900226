<template>
  <div v-if="documents.length > 0">
    <h4>Documents</h4>
    <div class="light-bg no-padding" style="overflow-x: auto">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in documents" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ d.filename }}</td>
            <td>
              <download-button :link="d.url" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "MemberDetailDependentList",
  props: {
    documents: {
      required: true,
    },
  },
};
</script>
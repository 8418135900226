<template>
  <div class="page-wrapper member-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="memberDetail_wrap vld-parent" ref="container">
        <div class="member_details">
          <div class="title">
            <h1>Member Details </h1>
          </div>
          
          <div class="row">
            <member-detail-information :personalInfo="personalInfo" :beneficiaryInfo="beneficiary_info"/>
     
            <div class="col-md-7">
              <div class="memberPolicy-wrap">
                <h2>Member Policies</h2>
                <div class="policy-block">
                  <span
                    class="userStatus"
                    :class="policy.status ? policy.status.toLowerCase() : ''"
                  >
                    <i class="far fa-check-circle"></i> {{ policy.status }}
                  </span>
                  <h3>Policy ID : {{ `#${policy.policy_id}` }}</h3>
                  <member-detail-policy :policy="policy" />
                  <member-detail-plan-list
                    :plans="plans"
                    :ccfee="policy.cc_fee"
                    :recurringAmount="recurringAmount"
                  />
                  <member-detail-billing
                    :billing="billingMethod"
                    type="billing"
                  />
                  <member-detail-billing
                    :billing="recurringBillingMethod"
                    type="recurring"
                  />
                  <member-detail-dependent-list :dependents="dependents" />
                  <member-detail-document-list
                    :documents="documents"
                    v-if="policy.status == 'ACTIVE'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import MemberDetailPolicy from "./partials/MemberDetailPolicy.vue";
import MemberDetailBilling from "./partials/MemberDetailBilling.vue";
import MemberDetailPlanList from "./partials/MemberDetailPlanList.vue";
import MemberDetailInformation from "./partials/MemberDetailInformation.vue";
import MemberDetailDependentList from "./partials/MemberDetailDependentList.vue";
import MemberDetailDocumentList from "./partials/MemberDetailDocumentList.vue";

export default {
  components: {
    MemberDetailPolicy,
    MemberDetailBilling,
    MemberDetailPlanList,
    MemberDetailInformation,
    MemberDetailDependentList,
    MemberDetailDocumentList,    
  },
  name: "MemberPolicyDetail",
  data: () => {
    return {
      personalInfo: "",
      policy: "",
      plans: [],
      billingMethod: "",
      recurringBillingMethod: "",
      recurringAmount: "",
      dependents: [],
      documents: [],
      breadcrumbItems: [],
      beneficiary_info:[]
    };
  },
  methods: {
    getBreadCrumbs() {
      this.breadcrumbItems = [
        {
          title: "View Members",
          path: {
            name: "MemberPolicyDetail",
          },
          active: false,
        },
        {
          title: "Member Details",
          active: true,
        },
      ];
    },
    getDetail() {
      const policyId = atob(this.$route.params.policyId);
      const url = `v2/view-member-policy-info/${policyId}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          const data = res.data.data;
          this.personalInfo = data.personal_info;
          this.policy = data.policy_detail;
          this.plans = data.plan_details;
          this.billingMethod = data.billing_method;
          this.recurringBillingMethod = data.recurring_billing_method;
          this.recurringAmount = data.recurring_amount;
          this.dependents = data.dependents;
          this.documents = data.documents;
          this.beneficiary_info= data.beneficiary_info

          loader.hide();
        })
        .catch(() => {
          setTimeout(() => {
            loader.hide();
          }, 2000);
          console.log("error", "Something went wrong");
        });
    },
  },
  created() {
    this.getDetail();
    this.getBreadCrumbs();
  },
};
</script>
<style src="@/assets/css/memberdetail.css"></style>
<style scoped>
.underwriting{
  background: #F29423;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper member-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    ref: "container",
    staticClass: "memberDetail_wrap vld-parent"
  }, [_c('div', {
    staticClass: "member_details"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('member-detail-information', {
    attrs: {
      "personalInfo": _vm.personalInfo,
      "beneficiaryInfo": _vm.beneficiary_info
    }
  }), _c('div', {
    staticClass: "col-md-7"
  }, [_c('div', {
    staticClass: "memberPolicy-wrap"
  }, [_c('h2', [_vm._v("Member Policies")]), _c('div', {
    staticClass: "policy-block"
  }, [_c('span', {
    staticClass: "userStatus",
    class: _vm.policy.status ? _vm.policy.status.toLowerCase() : ''
  }, [_c('i', {
    staticClass: "far fa-check-circle"
  }), _vm._v(" " + _vm._s(_vm.policy.status) + " ")]), _c('h3', [_vm._v("Policy ID : " + _vm._s("#".concat(_vm.policy.policy_id)))]), _c('member-detail-policy', {
    attrs: {
      "policy": _vm.policy
    }
  }), _c('member-detail-plan-list', {
    attrs: {
      "plans": _vm.plans,
      "ccfee": _vm.policy.cc_fee,
      "recurringAmount": _vm.recurringAmount
    }
  }), _c('member-detail-billing', {
    attrs: {
      "billing": _vm.billingMethod,
      "type": "billing"
    }
  }), _c('member-detail-billing', {
    attrs: {
      "billing": _vm.recurringBillingMethod,
      "type": "recurring"
    }
  }), _c('member-detail-dependent-list', {
    attrs: {
      "dependents": _vm.dependents
    }
  }), _vm.policy.status == 'ACTIVE' ? _c('member-detail-document-list', {
    attrs: {
      "documents": _vm.documents
    }
  }) : _vm._e()], 1)])])], 1)])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v("Member Details ")])]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dependents.length > 0 ? _c('div', [_c('h4', [_vm._v("Dependents")]), _c('div', {
    staticClass: "light-bg no-padding",
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.dependents, function (dependent, index) {
    return _c('tr', {
      key: dependent.dependent_id
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(_vm.dependentFullName(dependent)))]), _c('td', [_vm._v(_vm._s(dependent.relationship))]), _c('td', [_vm._v(" " + _vm._s(dependent.birth_date ? dependent.birth_date : "--") + " ")]), _c('td', [_vm._v(_vm._s(dependent.ssn))]), _c('td', [_vm._v(_vm._s(dependent.gender))])]);
  }), 0)])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th'), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Relation")]), _c('th', [_vm._v("DOB")]), _c('th', [_vm._v("SSN")]), _c('th', [_vm._v("Gender")])])]);
}]

export { render, staticRenderFns }
<template>
  <div class="col-md-4">
    <div class="memberInformation">
      <h2>Primary Information</h2>
      <div class="memberProfile_Image">
        <img :src="image" class="img-fluid" alt="" />
      </div>
      <div class="text-center">
        <h3>{{ fullname }}</h3>
        <span class="memberCntc">
          <i class="fas fa-envelope"></i>
          {{ personalInfo.email }}
          <valid-icon
            :is-valid="personalInfo.is_email_valid"
            className="valid-icon"
          />
        </span>
        <span class="memberCntc">
          <i class="fas fa-phone-alt"></i>
          {{ formattedPhone(personalInfo.phone_1) }}
          <valid-icon
            :is-valid="personalInfo.is_phone1_valid"
            className="valid-icon"
          />
        </span>
      </div>
      <div class="light-bg">
        <div class="memberDetail_lists">
          <ul>
            <li>
              <span class="strong">Gender:</span>
              <span>{{ personalInfo.gender }}</span>
            </li>
            <li>
              <span class="strong">DOB:</span>
              <span>{{ personalInfo.birth_date }}</span>
            </li>
            <li>
              <span class="strong">SSN:</span>
              <span>{{ personalInfo.ssn }}</span>
            </li>
            <li>
              <span class="strong">Phone 1:</span>
              <span>
                {{ formattedPhone(personalInfo.phone_1) }}
                <valid-icon
                  v-if="formattedPhone(personalInfo.phone_1) != 'N/A'"
                  :is-valid="personalInfo.is_phone1_valid"
                />
              </span>
            </li>
            <li>
              <span class="strong">Alt. Phone:</span>
              <span>
                {{ formattedPhone(personalInfo.phone_2) }}
                <valid-icon
                  v-if="formattedPhone(personalInfo.phone_2) != 'N/A'"
                  :is-valid="personalInfo.is_phone2_valid"
                />
              </span>
            </li>
          </ul>
        </div>
      </div>

      <h4>Address Information</h4>

      <div class="light-bg">
        <div class="memberDetail_lists" v-if="personalInfo.address">
          <ul>
            <li>
              <span class="strong">Address 1:</span>
              <span>
                {{
                  personalInfo.address.address1
                    ? personalInfo.address.address1
                    : 'N/A'
                }}
              </span>
            </li>
            <li>
              <span class="strong">Address 2:</span>
              <span>
                {{
                  personalInfo.address.address2
                    ? personalInfo.address.address2
                    : 'N/A'
                }}
              </span>
            </li>
            <li>
              <span class="strong">City:</span>
              <span>{{ personalInfo.address.city }}</span>
            </li>
            <li>
              <span class="strong">State:</span>
              <span>{{ personalInfo.address.state }}</span>
            </li>
            <li>
              <span class="strong">ZIP Code:</span>
              <span>{{ personalInfo.address.zip }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div v-if="beneficiaryInfo.length > 0">
          <h4>Beneficiary Information</h4>
          <div class="beneficiary-card">
            <div class="light-bg table_content">
              <table>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Relation</td>
                    <td>Type</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in beneficiaryInfo" :key="index">
                    <td>{{ item.first_name }} {{ item.last_name }}</td>
                    <td>{{ item.relationship }}</td>
                    <td>
                      {{ item.is_contigent ? 'Contingent' : 'Beneficiary' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Helper from '@/resource/Helper'
import defaultImage from '@/assets/images/default_profile.jpg'

export default {
  name: 'MemberDetailInformation',
  props: {
    personalInfo: {
      required: true,
    },
    beneficiaryInfo: {
      required: true,
    },
  },
  computed: {
    fullname() {
      if (this.personalInfo) {
        let personalInfo = this.personalInfo
        let middleName =
          personalInfo.middle_name !== null ? personalInfo.middle_name : ''
        return `${personalInfo.first_name} ${middleName} ${personalInfo.last_name}`
      }
      return ''
    },
    image() {
      return this.personalInfo.image ? this.personalInfo.image : defaultImage
    },
  },
  methods: {
    formattedPhone(phone) {
      if (phone) {
        return Helper.formatPhoneNumber(phone)
      }
      return 'N/A'
    },
  },
}
</script>
<style>
.valid-icon {
  width: 4% !important;
}

.beneficiary-card .table_content table {
  width: calc(100% - 5px);
}

.beneficiary-card .table_content thead tr {
  position: inherit;
  background-color: transparent;
}

.beneficiary-card .table_content thead tr td {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 12px;
  padding-left: 0;
  vertical-align: top;
  color: #666;
}

.beneficiary-card .table_content tbody tr td {
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 0;
  vertical-align: top;
  color: #666;
}

.beneficiary-card .table_content tbody tr td:first-child {
  color: #666;
}
</style>

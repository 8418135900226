<template>
  <div class="light-bg">
    <ul>
      <li>
        <span class="strong">Enrollment Date:</span>
        <span>{{ policy.enrollment_date }}</span>
      </li>
      <li>
        <span class="strong">Effective Date:</span>
        <span>{{ policy.effective_date }} </span>
      </li>
      <li>
        <span class="strong">Billing Date:</span>
        <span>{{ policy.bill_date }} </span>
      </li>
      <li v-if="policy.status == 'TERMED' || policy.status == 'WITHDRAWN'">
        <span class="strong">Term Date:</span>
        <span>
          {{ policy.term_date }}
        </span>
      </li>
    </ul>
  </div>
</template>d
<script>
export default {
  name: "MemberDetailPolicy",
  props: {
    policy: {
      required: true,
    },
  },
};
</script>
<template>
  <div>
    <h4>
      <span v-if="type == 'billing'"> Billing Method </span>
      <span v-else> Recurring Billing Method </span>
    </h4>
    <div class="light-bg">
      <span class="billing-categ"> {{ billing.payment_type }} </span>
      <ul v-if="billing.details">
        <li>
          <span class="strong">Bank Name:</span
          ><span> {{ billing.details.name }}</span>
        </li>
        <li>
          <span class="strong">Account Name:</span
          ><span> {{ billing.details.account_name }}</span>
        </li>
        <li>
          <span class="strong">Account Number:</span
          ><span> {{ billing.details.number }}</span>
        </li>
        <li  v-if="billing.details.account_type">
          <span class="strong">Account Type:</span
          ><span> {{ billing.details.account_type | capitalize}}</span>
        </li>
        <li v-if="billing.details.account_holder_type">
          <span class="strong">Account Holder Type:</span
          ><span> {{ billing.details.account_holder_type | capitalize}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "MemberDetailBilling",
  props: {
    billing: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v("Plans")]), _c('div', {
    staticClass: "light-bg"
  }, [_c('ul', [_vm._l(_vm.plans, function (plan, index) {
    return _c('li', {
      key: index,
      class: plan.plan_status == 'TERMED' || plan.plan_status == 'WITHDRAWN' ? 'plan-inactive' : ''
    }, [_c('span', {
      staticClass: "strong"
    }, [_vm._v(" " + _vm._s(plan.plan_name_system_new) + ": "), plan.plan_status == 'TERMED' || plan.plan_status == 'WITHDRAWN' ? [_c('br'), _vm._v(" ( " + _vm._s(plan.plan_termed_date) + " ) ")] : _vm._e()], 2), _c('span', [_vm._v(_vm._s(_vm._f("currency")(plan.plan_price)))])]);
  }), _vm.ccfee > 0 ? _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Payment Service Fee:")]), _c('span', [_vm._v(_vm._s(_vm._f("currency")(_vm.ccfee)))])]) : _vm._e(), _c('hr'), _c('li', [_c('span', {
    staticClass: "strong"
  }, [_vm._v("Recurring Monthly:")]), _c('span', [_vm._v(_vm._s(_vm._f("currency")(_vm.recurringAmount)))])])], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./MemberPolicyDetail.vue?vue&type=template&id=23d6d8f0&scoped=true&"
import script from "./MemberPolicyDetail.vue?vue&type=script&lang=js&"
export * from "./MemberPolicyDetail.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/memberdetail.css?vue&type=style&index=0&lang=css&"
import style1 from "./MemberPolicyDetail.vue?vue&type=style&index=1&id=23d6d8f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d6d8f0",
  null
  
)

export default component.exports